import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import {
  FormModifier,
  FormSection,
  Input,
  LookupMultiSelect,
  SingleSelect,
  Textarea,
} from 'hudl-uniform-ui-components';
import pageHeroData from '../../../data/pages/patterns.yml';
import formPreviewData from '../../../data/previews/forms-patterns.yml';
import styles from './styles/forms.module.scss';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedElement: 'title',
      selectedType: 'add',
      selectedState: 'default',
    };
  }

  handleElementChange(value) {
    this.setState({
      selectedElement: value,
    });
  }

  handleTypeChange(value) {
    this.setState({
      selectedType: value,
    });
  }

  handleStateChange(value) {
    this.setState({
      selectedState: value,
    });
  }

  render() {
    return (
      <PageWithSubNav pageType="design" title="Forms" subnav="patterns">
        <PageHero heroData={pageHeroData} tierTwo="Forms" hideHeader />
        <PageNavigation>
          <PageNavigationLink>Elements</PageNavigationLink>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>State</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
        </PageNavigation>

        <Section title="Elements">
          <Paragraph>
            The following guidelines reference many different parts of the form.
            Take a quick look at what each one is and where it sits.
          </Paragraph>
          <ComponentPreview
            name="ElementsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={formPreviewData.elements}
            onChange={this.handleElementChange.bind(this)}>
            <div
              className={
                styles.elementsWrapper + ' ' + this.state.selectedElement
              }>
              <FormSection sectionText="Personal Information">
                <Input
                  label="Name"
                  type="text"
                  required
                  placeholder="First and last"
                  helpText="No initials"
                />
                <Input
                  label="Email"
                  type="email"
                  required
                  placeholder="blank@test.com"
                  helpText="We'll use this for your login"
                />
              </FormSection>
            </div>
          </ComponentPreview>
        </Section>
        <Section title="Type">
          <Paragraph>
            Of all the forms in Hudl, we found four common cases. Each has its
            own musts and maybes.
          </Paragraph>
          <ComponentPreview
            name="TypesContainer"
            layout="halfWidth"
            hideEnvironmentToggle
            previewData={formPreviewData.types}
            onChange={this.handleTypeChange.bind(this)}>
            {this.state.selectedType === 'add' && (
              <FormModifier density="compact">
                <Input type="text" label="Name" defaultValue="Peter Kavinsky" />
                <Input
                  type="email"
                  label="Email"
                  defaultValue="laxlyfe@internet.com"
                />
                <SingleSelect
                  label="Role"
                  defaultValue="athlete"
                  options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Coach', value: 'coach' },
                    { label: 'Athlete', value: 'athlete' },
                  ]}
                />
                <SingleSelect
                  label="Graduation year"
                  defaultValue="2020"
                  options={[
                    { label: '2019', value: '2019' },
                    { label: '2020', value: '2020' },
                    { label: '2021', value: '2021' },
                  ]}
                />
              </FormModifier>
            )}
            {this.state.selectedType === 'edit' && (
              <FormModifier density="compact">
                <Input
                  type="text"
                  label="Name"
                  defaultValue="Peter Covey"
                  autoFocus
                />
                <Input
                  type="email"
                  label="Email"
                  defaultValue="laxlyfe@internet.com"
                  disabled
                />
                <SingleSelect
                  label="Role"
                  defaultValue="athlete"
                  options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Coach', value: 'coach' },
                    { label: 'Athlete', value: 'athlete' },
                  ]}
                />
                <SingleSelect
                  label="Graduation year"
                  defaultValue="2020"
                  options={[
                    { label: '2019', value: '2019' },
                    { label: '2020', value: '2020' },
                    { label: '2021', value: '2021' },
                  ]}
                />
              </FormModifier>
            )}
            {this.state.selectedType === 'submit' && (
              <FormModifier density="compact">
                <SingleSelect
                  label="Invoice"
                  defaultValue="lacrosse"
                  options={[
                    {
                      label: "Adler High boys' varsity lacrosse",
                      value: 'lacrosse',
                    },
                    {
                      label: "Adler High boys' varsity football",
                      value: 'football',
                    },
                  ]}
                />
                <Input
                  type="text"
                  label="Cardholder Name"
                  defaultValue="Josh Sanderson"
                />
                <Input
                  type="email"
                  label="Email"
                  defaultValue="letterwinner5@internet.com"
                />
                <Input
                  type="number"
                  label="Card Number"
                  defaultValue="1234567890123456"
                />
              </FormModifier>
            )}
            {this.state.selectedType === 'share' && (
              <FormModifier density="compact">
                <SingleSelect
                  label="Playlist"
                  defaultValue="bayside"
                  options={[
                    { label: 'Adler vs. Bayside assists', value: 'bayside' },
                    {
                      label: 'Adler vs. Courtenay assists',
                      value: 'courtenay',
                    },
                  ]}
                />
                <LookupMultiSelect
                  label="Recipients"
                  defaultValues={[
                    { label: 'Peter Kavinsky', value: 'peter' },
                    { label: 'Lucas', value: 'lucas' },
                  ]}
                  options={[
                    { label: 'Peter Kavinsky', value: 'peter' },
                    { label: 'Josh Sanderson', value: 'josh' },
                    { label: 'Kenny', value: 'kenny' },
                    { label: 'Lucas', value: 'lucas' },
                  ]}
                />
                <Textarea
                  label="Notes"
                  defaultValue="Check these clips and report back before ski trip next week."
                  minHeight="125px"
                />
              </FormModifier>
            )}
          </ComponentPreview>
        </Section>

        <Section title="State">
          <ComponentPreview
            name="StatesContainer"
            layout="halfWidth"
            hideEnvironmentToggle
            previewData={formPreviewData.states}
            onChange={this.handleStateChange.bind(this)}>
            {this.state.selectedState === 'default' && (
              <Input
                type="email"
                label="Email"
                defaultValue="letterwinner5@internet.com"
                autoFocus
              />
            )}
            {this.state.selectedState === 'disabled' && (
              <Input
                type="email"
                label="Email"
                defaultValue="letterwinner5@internet.com"
                disabled
              />
            )}
            {this.state.selectedState === 'error' && (
              <Input
                type="email"
                label="Email"
                defaultValue="letterwinner5@internet"
                hasError
                helpText="Make sure this is a valid address."
              />
            )}
          </ComponentPreview>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Multiple Fields</SectionSubhead>
          <Paragraph>
            A true form is always two or more fields. If all you need is one
            field’s worth of information, add it to an existing (related)
            container.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="chop up the workflow with isolated fields."
              img="forms-multiple-fields-next-dont"
            />
            <DontDoItem
              type="do"
              text="display all fields together for one cohesive form."
              img="forms-multiple-fields-next-do"
            />
          </DontDo>
          <Paragraph>
            In the rare case separate panes are preferred, tie the fields
            together by communicating progress. Just be sure the user can go
            'back' if necessary. If they can’t, don't split the form at all.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="force a user through a series of steps."
              img="forms-multiple-fields-progress-dont"
            />
            <DontDoItem
              type="do"
              text="allow them to backtrack and progress however they'd like."
              img="forms-multiple-fields-progress-do"
            />
          </DontDo>
          <Paragraph>
            Stick with 'next' for forward progress. Avoid ‘save’ unless they can
            abandon the form and finish later.
          </Paragraph>
          <SectionSubhead>Required</SectionSubhead>
          <Paragraph>
            For a longer form (5+ fields), use an asterisk to denote required
            information.
          </Paragraph>
          <Paragraph>
            If the <em>entire</em> form is required, use a note to save everyone
            from the chaos of a million asterisks.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="overwhelm or distract by marking every field."
              img="forms-required-dont"
            />
            <DontDoItem
              type="do"
              text="clearly state upfront that all information is required."
              img="forms-required-do"
            />
          </DontDo>
          <SectionSubhead>Layout</SectionSubhead>
          <Paragraph>
            Prioritize fields the way you'd prioritize the info itself. If you
            get stuck, think about how you'd ask the questions out loud. What
            would kick off the <em>interview</em>?
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="randomly add fields with no clear order."
              img="forms-layout-dont"
            />
            <DontDoItem
              type="do"
              text="organize the form as its own conversation."
              img="forms-layout-do"
            />
          </DontDo>
          <Paragraph>
            For forms with a mix of required and optional, put required fields
            at the top (ideally all together, but if that doesn’t work
            logically, don’t force it).
          </Paragraph>
          <Paragraph>
            “Obvious” order applies to actual steps, as well as info with a
            common display, like addresses. (In the rare case we ask for{' '}
            <strong>country</strong>, put it last—after city, state and zip
            code. It saves us from disrupting the order by adding or removing
            when necessary.)
          </Paragraph>
          <Paragraph>
            For larger forms, take full advantage of the{' '}
            <Link href="/components/forms/form-section/design">
              form section component
            </Link>
            . Think personal info, contact info, shipping, billing, etc.
          </Paragraph>
          <SectionSubhead>Recipients</SectionSubhead>
          <Paragraph>
            When using a form to share, don't use 'To:' anywhere.
          </Paragraph>
          <Paragraph>
            Sending a message could be <em>to</em> someone, but when sharing
            video and data, <em>with</em> is more natural. A{' '}
            <strong>recipients</strong> field fits both.
          </Paragraph>

          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use 'To:' as a field label or placeholder."
              img="forms-recipients-dont"
            />
            <DontDoItem
              type="do"
              text="provide a spot for the user to list recipients."
              img="forms-recipients-do"
            />
          </DontDo>
          <Paragraph>
            Share recipients are usually chosen from a multi-select, so be sure
            to check those{' '}
            <Link href="/components/selects/lookup-multi-select/design#Microcopy">
              placeholder guidelines
            </Link>
            .
          </Paragraph>
          <Paragraph>
            For messages, the placeholder should help the user enter recipients
            (e.g.,
            <em>Separate multiple emails with a comma</em>
            ).
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Titles aren’t required, but they help the user understand what
            completing a form will do.
          </Paragraph>
          <Paragraph>
            Keep it brief, 4-5 words max. It shouldn’t be a complete thought,
            just the purpose of the form itself.
          </Paragraph>

          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            Check{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>

          <SectionSubhead>Placeholder</SectionSubhead>
          <Paragraph>
            Placeholders are suggested but not always necessary. They shouldn’t
            repeat the label outright or insert the label into a CTA (e.g.,{' '}
            <em>Enter your email address</em>
            ).
          </Paragraph>
          <Paragraph>
            Use one to provide an example or preferred format, like a date or
            phone number.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="repeat the label for the sake of having a placeholder."
              img={'textinput-placeholder-dont'}
            />
            <DontDoItem
              type="do"
              text="provide more instruction with a sample entry."
              img={'textinput-placeholder-do'}
            />
          </DontDo>
          <Paragraph>
            Placeholders should follow{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              non-label text guidelines
            </Link>{' '}
            <em>without</em> punctuation.
          </Paragraph>

          <SectionSubhead>Help Text</SectionSubhead>
          <Paragraph>
            Help text can provide details not included in the label or
            placeholder, like certain criteria to meet for a valid submission.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use the help text to provide a sample entry."
              img={'textinput-helptext-dont'}
            />
            <DontDoItem
              type="do"
              text="provide any additional context for that field."
              img={'textinput-helptext-do'}
            />
          </DontDo>
          <Paragraph>
            In the case of an error, update the help text to explain why an
            error occurred and how it’s fixed.
          </Paragraph>

          <SectionSubhead>CTAs</SectionSubhead>
          <Paragraph>
            For any add, edit or create workflow, use <em>save</em> to indicate
            the new information will be stored. Include the object for{' '}
            <Link href="/words/content-elements/calls-to-action">
              congruence
            </Link>
            .
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="be vague with a form's CTA."
              img="forms-cta-save-dont"
            />
            <DontDoItem
              type="do"
              text="clearly communicate what the button will accomplish."
              img="forms-cta-save-do"
            />
          </DontDo>
          <Paragraph>
            For <em>share</em> and <em>send</em>, clearly call out which thing
            they’re doing. The same goes for <em>submit</em>
            —they shouldn’t expect anything in return.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
